




































import PListView from '@core/components/p-list-view/p-list-view.vue';
import Vue from 'vue';
import GetBusinessesRequestViewModel from '@/src/services/viewModel/request/Memberships/GetBusinessesRequestViewModel';

export default Vue.extend({
  auth: true,
  hasBackButton: true,
  data: () => ({
    filter: {},
    businesses: {},
    requestError: undefined,
  }),
  computed: {
    columns(): any {
      return [
        { value: this.$t('page.memberData.title.company'), field: 'name' },
        { value: this.$t('page.memberData.select.zipCode'), field: 'postalCode' },
        { value: this.$t('page.memberData.select.city'), field: 'city' },
        { value: this.$t('page.memberData.select.category'), field: 'categoryName' },
        // { value: this.$t('page.memberData.select.gastronomyType'), field: 'gastronomyTypeName' },
        {
          // TODO: Make a slot and use p-checkbox instead when p-list-view supports it
          // p-list-view has no custom slots for columns yet, so used format to display bool instead
          value: this.$t('page.memberData.select.activ'), field: 'isActive', format: (entry: boolean) => (entry ? '✔️' : '❌'), textAlign: 'center',
        },
      ];
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        const businessesList = this.$refs.businessesList as InstanceType<typeof PListView>;
        this.$debounce(() => {
          businessesList.populateList();
        }, 500, this)();
      },
    },
  },
  methods: {
    async populateData(params: {query: any}) {
      try {
        const query = new GetBusinessesRequestViewModel().fromModel({ ...params.query, ...this.filter });
        const response = await this.$service.api.memberships.getListOfMyBusinesses(query);
        return response;
      } catch (error: any) {
        this.requestError = error;
      }
      return {} as any;
    },
  },
});
